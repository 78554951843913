/**
 * This is base URL for api call
 */

export let BASE_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://toolofrontapi.meancloud.in/api'; // 'http://localhost:5000/api';
} else {
  BASE_URL = 'https://toolofrontapi.meancloud.in/api';
}

/**
 * Basic URLs
 */

//authentication
export const AUTH_URL = `${BASE_URL}/auth`;
export const REGISTRATION_URL = `${AUTH_URL}/register`;
export const LOGIN_URL = `${AUTH_URL}/login`;
export const GOOGLE_LOGIN_URL = `${AUTH_URL}/login-google`;
export const FACEBOOK_LOGIN_URL = `${AUTH_URL}/login-facebook`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const CHANGE_DEFAULT_PROFILE = `${AUTH_URL}/change-default-children`;
export const REQUEST_PASSWORD_RESET = `${AUTH_URL}/request-password-reset`;
export const PASSWORD_RESET = `${AUTH_URL}/password-reset`;
export const CHANGE_PASSWORD = `${AUTH_URL}/change-password`;

export const QUICK_REGISTER = `${BASE_URL}/quick-registers`;

//user
export const USER_URL = `${BASE_URL}/users`;
export const USER_PROFILE = `${USER_URL}/my-profile`;
export const USER_REQUESTS = `${BASE_URL}/user-requests/lend-return-requests`;

//author
export const AUTHORS_URL = `${BASE_URL}/authors`;

//genre
export const GENRE_URL = `${BASE_URL}/genres`;

//cities
export const CITY_URL = `${BASE_URL}/cities`;

//age-groups
export const AGE_GROUP_URL = `${BASE_URL}/age-groups`;

//sub profile
export const SUB_PROFILE_URL = `${BASE_URL}/profiles`;

//books
export const BOOKS_URL = `${BASE_URL}/books`;
export const BOOK_RIBBONS_URL = `${BOOKS_URL}/browse-books-by-ribbons`;
export const BROWSE_BOOKS = `${BOOKS_URL}/browse-books`;
export const HOME_RIBBONS_URL = `${BOOKS_URL}/home-page-books-by-ribbons`;

//cart
export const CART_URL = `${BASE_URL}/carts`;
export const CART_COUNT = `${BASE_URL}/carts/count`;

//wishlist
export const WISHLIST_URL = `${BASE_URL}/wishlists`;

//Donations
export const DONATED_BOOKS_URL = `${BASE_URL}/book-donors/my-donations`;

//orders
export const ORDER_URL = `${BASE_URL}/orders`;
export const ACTIVE_ORDERS = `${ORDER_URL}/active-orders`;
export const PICKUP_ORDERS = `${ORDER_URL}/pickup-orders`;
export const ORDER_HISTORY = `${ORDER_URL}/order-history`;
export const UPCOMING_RETURNS = `${ORDER_URL}/upcoming-returns`;

//subscriptions
export const SUBSCRIPTION_URL = `${BASE_URL}/subscriptions`;

//admin contact
export const CONTACTS_URL = `${BASE_URL}/contacts`;

//media
export const MEDIA_URL = `${BASE_URL}/media`;
export const PROFILE_IMAGES = `${MEDIA_URL}/profile-icons`;

//payments
export const PAYMENT_URL = `${BASE_URL}/payments`;
export const FREE_TRIAL = `${BASE_URL}/payments/free-trial`;

//faq
export const FAQ_URL = `${BASE_URL}/faqs`;
export const TESTIMONIAL_URL = `${BASE_URL}/testimonials`;

//blogs
export const BLOG_URL = `${BASE_URL}/blogs`;

//recommendations
export const RECOMMENDATIONS_URL = `${BASE_URL}/recommendations`;

//ambassadors
export const AMBASSADOR_URL = `${BASE_URL}/ambassador-requests`;

//donors
export const DONOR_URL = `${BASE_URL}/donations`;

//events
export const EVENT_URL = `${BASE_URL}/events`;
export const PAST_EVENTS = `${EVENT_URL}/past-events`;
export const UPCOMING_EVENTS = `${EVENT_URL}/upcoming`;
export const EVENT_REGISTRATION = `${BASE_URL}/event-registrations`;

//fine
export const FINE_URL = `${BASE_URL}/fines`;

// Preview Page
export const LIBRARY_PREVIEW_URL = `${BASE_URL}/books/browse-books-by-ribbons-for-preview`;
export const COMMUNITY_DETAILS_URL = `${BASE_URL}/communities`;

// Referrals
export const MY_REFERRALS = `${BASE_URL}/referrals`;
export const REFERRAL_STATS = `${BASE_URL}/referrals/stats`;
export const REDEEM_REFERRALS = `${BASE_URL}/payments/redeem-referral`;

export const PRE_SCHOOL_URL = `${BASE_URL}/pre-schools`;

export const BOOK_SEARCH_URL = `${BOOKS_URL}/quick-search`;
